import React, { useState, useEffect, useRef } from 'react';
import './ChatContainer.css';
import { UserAuth } from '../context/AuthContext';
import Modal from './Modal';
import { useNavigate } from 'react-router-dom';


function ChatContainer({ sendRequestToGPT, answer }) {
  const [goal, setGoal] = useState('');
  const [Restricao, setRestricao] = useState('');
  const [Dias, setDias] = useState('');
  const [Preferencia,setPreferencia] = useState('');
  const [genero, setGenero] = useState('');
  const [numeroExercicios, setNumeroExercicios] = useState(4);
  const [selectedFoods3, setSelectedFoods3] = useState([]);
  const resultTextareaRef = useRef(null);

  
  const availableFoodsFrutas = ['Braço', 'Ombro', 'Peitos', 'Costas', 'Perna', 'Glúteo', 'Panturrilha', 'Abdomem'];
  //

  const [error, setError] = useState('');
  const [recomend, setRecomend] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const { retornaTicket, retirarTicket, adicionarTreino } = UserAuth();
  const [userTickets, setUserTickets] = useState(null);
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleSave = () => {
    adicionarTreino(resultTextareaRef.current.value);
    navigate('/meutreino')
    return;
  };


  function handleFoodSelection(selectedFoods, setSelectedFoods, food) {
    if (selectedFoods.includes(food)) {
      setSelectedFoods(selectedFoods.filter((item) => item !== food));
    } else if (selectedFoods.length < 6) {
      setSelectedFoods([...selectedFoods, food]);
    }
  }

  const openBuyModal = () => {
    setIsBuyModalOpen(true);
  };

  const closeBuyModal = () => {
    setIsBuyModalOpen(false);
  };

  useEffect(() => {
    retornaTicket()
      .then((tickets) => {
        if (tickets !== null) {
          setUserTickets(tickets);
        } else {
          setUserTickets('Nenhum ticket disponível');
        }
      })
      .catch((error) => {
        setUserTickets('Erro ao obter os tickets');
      });
  }, [retornaTicket]);

  useEffect(() => {
    const messages = [
      'GERANDO SEU TREINO 💪',
      'FAZENDO NOSSO MELHOR🏆',
      'NÃO SAIA DA PAGINA⏳',
      'MONTANDO UM TREINO TOP 💪',
    ];
    let currentIndex = 0;

    const interval = setInterval(() => {
      setLoadingMessage(messages[currentIndex]);
      currentIndex = (currentIndex + 1) % messages.length;
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleSubmit = async () => {
    if (userTickets === 0) {
      openBuyModal();
      return;
    } else if ( !goal) {
      setError('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    setIsLoading(true);
    document.body.classList.add('scroll-lock');

    const data = `
    Faça um TREINO personalizado para ${Dias} com ênfase em ${goal} informando ${numeroExercicios} Exercicios por dia com no minimo 4 series e no mininmo 10 repetições . O meu género é ${genero}, o meu nível é ${Restricao}, e o treino deve ser no local: ${Preferencia}. Além disso, gostaria de incluir exercícios específicos para fortalecer os meus músculos favoritos, que são ${selectedFoods3}. Não inclua informações adicionais e nem observações APENAS o TREINO"  `;

console.log(data)


    try {

      if (userTickets === "TreinoNormal" ) {

        await sendRequestToGPT(data);
      } 

      setGoal('');
      setSelectedFoods3([]);

      setError('');
      setRecomend('Aviso: Para salvar seu Treino clique em Baixar treino PDF');
      retirarTicket();
    } catch (err) {

      setError('Ocorreu um erro ao processar a solicitação.');
    } finally {
      setIsLoading(false);
      document.body.classList.remove('scroll-lock');
    }


  };


  return (
    <div className="chat-container w-4/5">
      <div className="chat-header">
        <h3 className='text-2xl sm:text-3xl'>INFORMAÇÕES</h3>
      </div>
      <div className="chat-input">
        <label htmlFor="goal">Qual seu objetivo?</label>
        <select id="goal" value={goal} onChange={(e) => setGoal(e.target.value)}>
          <option value="">Selecione seu objetivo</option>
          <option value="Emagrecimento com facilidade">Treino para Emagrecer</option>
          <option value="Massa Muscular">Treino para Massa Muscular</option>
          <option value="Definição e ganho de Massa Muscular">Definição + Massa Muscular</option>
          <option value="Definição Muscular">Treino para Definição</option>
   
        </select>
      </div>
      <div className="chat-input">
        <label htmlFor="Genero">Gênero</label>
        <select id="genero" value={genero} onChange={(e) => setGenero(e.target.value)}>
          <option value="">Informe seu gênero</option>
          <option value="Masculino">Masculino</option>
          <option value="Feminino">Feminino</option>
        </select>
      </div>
      <div className="chat-input">
        <label htmlFor="Restricao">Qual seu nivel?</label>
        <select id="Restricao" value={Restricao} onChange={(e) => setRestricao(e.target.value)}>
          <option value="Iniciante">Iniciante</option>
          <option value="Intermediario">Intermediario</option>
          <option value="Avançado">Avançado</option>
        </select>
      </div>
      <div className="chat-input">
        <label htmlFor="Preferencia">Preferencia de Treino</label>
        <select id="Preferencia" value={Preferencia} onChange={(e) => setPreferencia(e.target.value)}>
          <option value="choose">Escolha uma</option>
          <option value="Na Academia">Academia</option>
          <option value="Em casa,faça com peso caseiros">Em casa</option>
          <option value="Calistenia">Calistenia</option>
        </select>
      </div>
      <div className="chat-input">
        <label htmlFor="Dias">Dias de Treino</label>
        <select id="Dias" value={Dias} onChange={(e) => setDias(e.target.value)}>
        <option value="choose">Escolha uma</option>
        <option value="Segunda até sexta">Segunda a sexta</option>
          <option value="Segunda até domingo">Segunda a domingo</option>
          <option value="Segunda quarta Sexta">Segunda Quarta Sexta</option>

        </select>
      </div>
      <div className="chat-input">
        <label htmlFor="Dias">Quantidade de Exercicios Por Dia</label>
        <select id="Dias" value={numeroExercicios} onChange={(e) => setNumeroExercicios(e.target.value)}>
        <option value="choose">Escolha uma Quantidade</option>
        <option value={4}>4</option>
          <option value={5}>5</option>
          <option value={6}>6</option>
          <option value={7}>7</option>

        </select>
      </div>

     
      <div className=" flex flex-col border rounded-lg font-bold mb-2 p-2">
        <label htmlFor="obs3">Preferencia Musculares</label>
        <div className='grid grid-rows-2 grid-flow-col flex-wrap'>
          {availableFoodsFrutas.map((food) => (
            <label key={food} className=' text-sm md:text-lg'>
              <input className='w-5 h-5 mr-1'
                type="checkbox"
                value={food}
                checked={selectedFoods3.includes(food)}
                onChange={() => handleFoodSelection(selectedFoods3, setSelectedFoods3, food)}
                disabled={selectedFoods3.length === 6 && !selectedFoods3.includes(food)}
              />
              {food}
            </label>
          ))}
        </div>
      </div>

      {userTickets === 0 ? (
        <div>
          <div className="error-message text-red-600 mb-3">
            Para montar sua dieta, é necessário adquirir. Aproveite, é mais barato que um REFRIGERANTE!
          </div>
          <button className="bg-red-500 text-white py-3 px-4 my-3 rounded-3xl" onClick={openBuyModal}>
            COMPRAR
          </button>

        </div>
      ) : (
        <div>
          <button className="bg-red-500 py-3 px-4 my-3 rounded-3xl" disabled={userTickets !== 'TreinoNormal'} onClick={handleSubmit}>
            GERAR TREINO
          </button>
          {error && (
            <div className="chat-error text-white bg-red-600 rounded-md mb-3">
              {error}
            </div>
          )}
        </div>
      )}
      {error && <div className="chat-error mb-5">{error}</div>}

      <div className="chat-input">
        <label htmlFor="response">Treino Personalizado</label>
        <textarea id="response"
          ref={resultTextareaRef}
          name="response"
          rows="20"
          value={answer}
          readOnly></textarea>
        {recomend && (
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
          }}>
            <div style={{
              backgroundColor: 'white',
              borderRadius: '10px',
              padding: '20px',
              maxWidth: '500px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              border: '1px solid #ddd',
              position: 'relative'
            }}>
              <div style={{
                position: 'absolute',
                top: '-25px',
                right: '20px',
                backgroundColor: '#FF4D61',
                color: 'white',
                borderRadius: '50%',
                width: '50px',
                height: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '24px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
              }}>
                ⚠️ {/* Ícone de aviso */}
              </div>
              <h3 style={{
                textAlign: 'center',
                color: '#333',
                fontWeight: 'bold',
                marginBottom: '15px'
              }}>
                Treino Gerado com Sucesso
              </h3>
              <p style={{
                textAlign: 'center',
                color: '#555',
                marginBottom: '20px'
              }}>
                Clique no botão abaixo para salvar seu Treino e visualizá-lo. Depois você poderá baixar em PDF.
              </p>
              <div style={{ textAlign: 'center' }}>
                <button onClick={handleSave} style={{
                  backgroundColor: '#FF4D61',
                  color: 'white',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  border: 'none',
                  cursor: 'pointer'
                }}>
                  SALVAR MEU TREINO
                </button>
              </div>
            </div>
          </div>
        )}

      </div>
     
      {isLoading && (
        <div className="overlay">
          <div className="loading-message">{loadingMessage}</div>
        </div>
      )}
      <Modal isOpen={isBuyModalOpen} onClose={closeBuyModal} />
    </div>
  );
}

export default ChatContainer;
