import React, { useState, useEffect } from "react";
import "./Modal.css";
import { Link } from "react-router-dom";
import Emagrecimento from "./../img/Emagrecimento1.png";
import MassaMuscular from "./../img/MassaMuscular.png"
import dietaTreino from "./../img/Treino.png"
import Dieta3 from "./../img/Dieta3Meses.png"
import definicao from "./../img/Definiçao.png"
import definicaomassa from "./../img/Definição+Massa.png"


const Product = ({ imageSrc, altText, description, idName }) => (
  <div className="product shadow-md rounded-md mb-2 p-2 bg-gray-100 md:w-4/5 md:mx-auto">
    <img
      src={imageSrc}
      alt={altText}
      className="product-image mt-1 mx-auto shadow-md"
      loading="eager"
    />
    <p className=" md:w-4/5 mx-auto">{description}</p>
    <Link to={idName}>
      <button className="mb-1 bg-red-500">COMPRAR</button>
    </Link>
  </div>
);

const Modal = ({ isOpen, onClose }) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    // Função para pré-carregar as imagens
    const preloadImages = () => {

      const imageArray = [
        Emagrecimento,
        Emagrecimento,
        Emagrecimento,
        Emagrecimento,
      ];
      const imagePromises = imageArray.map((src) => {
        const image = new Image();
        image.src = src;
        return new Promise((resolve) => {
          image.onload = resolve;
        });
      });

      Promise.all(imagePromises)
        .then(() => {
          setImagesLoaded(true);
        })
        .catch((error) => {
          console.error("Erro ao carregar imagens:", error);
        });
    };

    // Pré-carrega as imagens apenas quando o modal é aberto
    if (isOpen && !imagesLoaded) {
      preloadImages();
    }
  }, [isOpen, imagesLoaded]);

  const modalClasses = isOpen ? "modal-overlay open" : "modal-overlay";
  const contentClasses = isOpen ? "modal-content open" : "modal-content";

  if (!isOpen) {
    return null;
  }

  return (
    <div className={modalClasses} onClick={onClose}>
      <div className={`${contentClasses} w-5/6 md:w-3/5`} onClick={(e) => e.stopPropagation()}>
        {imagesLoaded ? (

          <>

            <button className="absolute px-3 py-2 bg-red-400 top-0 right-1 md:top-3 md:right-3" onClick={onClose}>
              X
            </button>
            <Product
              imageSrc={Emagrecimento}
              altText="Produto 1"
              description="Isso mesmo! 🎉 O mais vendido e o mais querido de todos(a). 🌟 Inúmeras pessoas que compraram amaram esse super Treino personalizado.🏋️‍♂️ "
              idName="/emagresce"
            />
            <Product
              imageSrc={dietaTreino}
              altText="Produto 3"
              description="🏋️‍♂️ Treinos + Definição = Sucesso! Alcance seus objetivos para definição mudsdsascular com nosso plano completo.🏋️‍♂️ "
              idName="/dietatreino"
            />
            <Product
              imageSrc={MassaMuscular}
              altText="Produto 2"
              description="Deseja ganhar massa muscular? Nossa dieta exclusiva com base suas informações fórmula para músculos maiores e melhores! 💪🍽️💥"
              idName="/massamuscular"
            />

            <Product
              imageSrc={definicao}
              altText="Produto 4"
              description="🚀 Alcance o próximo nível para ganhar corpo. Nosso treino é o foguete que você precisa! 🚀💪"
              idName="/definicao"
            />
            <Product
              imageSrc={definicaomassa}
              altText="Produto 5"
              description="💪 Musculos maiores, definidos, e boa maturidade muscular : o combo perfeito para ganho de massa e definição + ganho muscular. 💪"
              idName="/definicaomassa"
            />
            <Product
              imageSrc={Dieta3}
              altText="Produto 5"
              description="Seu treino exclusivo podendo ser adaptada semanalmente durante 3 meses de acordo com seus objetivos e preferências, tornando-a flexível para o seu estilo de vida. 😊"
              idName="/dieta3meses"
            />
          </>
        ) : (
          <div className="loading-indicator">Carregando...</div>
        )}
      </div>
    </div>
  );
};

export default Modal;
