import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './HamburgerMenu.module.css';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';

function HamburgerMenu({ isOpen, toggleMenu }) {
  const { user, logout, retornaTicket } = UserAuth();
  const [userTickets, setUserTickets] = useState(null);
  const navigate = useNavigate();

  const ContatoSuporte = () => {
    navigate('/suporte')
  }

  const LocalTreino = () => {
    navigate('/meutreino')
  }

  useEffect(() => {

    retornaTicket()
      .then(async (tickets) => {
        if (tickets !== null) {
          await setUserTickets(tickets);

        } else {
          setUserTickets('Nenhum ticket disponível');
        }

      })
      .catch((error) => {
        console.error('Erro ao obter os tickets:', error);
        setUserTickets('Erro ao obter os tickets');
      })
  }, [retornaTicket]);


  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      console.log('You are logged out');
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div>
      <div className={styles['menu-toggle']} onClick={toggleMenu}>
        <div className={`${styles['menu-bar']} ${isOpen ? styles.open : ''}`}></div>
        <div className={`${styles['menu-bar']} ${isOpen ? styles.open : ''}`}></div>
        <div className={`${styles['menu-bar']} ${isOpen ? styles.open : ''}`}></div>
      </div>
      {isOpen && (
        <div className={`${styles['hamburger-menu']} ${isOpen ? styles.open : ''} bg-gray-800 w-4/5 sm:w-1/2`}>
          <div className={`${styles['close-button']}`} onClick={toggleMenu}>
            X
          </div>
          <nav>
            <ul className=' p-0'>


            <li className="border-y " onClick={LocalTreino}>Acessar Meu Treino</li>
              <li className="border-y " onClick={ContatoSuporte}>Fale Conosco</li>
             
              <p className='mt-5'>Email: {user && user.email}</p>
              <p className='mb-5'>Ticket:{userTickets}</p>

              <button className='rounded-lg p-2 bg-red-500' onClick={handleLogout}>Sair da Conta</button>

            </ul>
          </nav>
        </div>
      )}
    </div>
  );
}

HamburgerMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

export default HamburgerMenu;
