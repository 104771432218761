import './Emagresce.css';
import { Link, useNavigate } from 'react-router-dom';


export default function Suporte() {
  const navigate = useNavigate();

  const Voltar = () => {
    navigate('/home')
  }
  return (
    <div className="w-full flex flex-col items-center pb-5">
      <h1 className='font-black m-5 p-2 text-red-600 border-b '>SUPORTE</h1>
      <Link to={"https://api.whatsapp.com/send?phone=5524999375062"} >
        <button className=" text-white px-10 py-3 rounded-lg  hover:bg-gray-800  bg-gradient-to-r from-red-400 to-red-700">
          Atendimento 1
        </button>
      </Link>
      <Link to={"https://api.whatsapp.com/send?phone=5524993288136"} >
        <button className="mt-5 mx-auto text-white px-10 py-3 rounded-lg  bg-gradient-to-r from-red-400 to-red-700 hover:bg-gray-800">
          Atendimento 2
        </button>
      </Link>
      <Link to={"https://instagram.com/nutriinteligenteoficial?igshid=OGQ5ZDc2ODk2ZA=="} >
        <button className="mt-5 mx-auto text-white px-10 py-3 rounded-lg  bg-gradient-to-r  from-red-400 to-red-700 hover:bg-gray-800">
          Instagram
        </button>
      </Link>
      <Link to={"http://www.tiktok.com/@nutriinteligenteonline"} >
        <button className="mt-5 text-white px-10 py-3 rounded-lg bg-gradient-to-r  from-red-400 to-red-700 hover:bg-gray-800" >
          Tiktok
        </button>
      </Link>


      <button className="mt-10 border mx-auto text-white px-10 py-3 rounded-lg bg-red-600" onClick={Voltar}>
        Voltar
      </button>
    </div>
  );
}
