import React, { useEffect, useState } from 'react';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';





export default function LocalDieta() {
  
  const [successMessageVisible, setSuccessMessageVisible] = useState(false)
  const [userTreino, setUserTreino] = useState(null);
  const { retornaTreino } = UserAuth();
  const navigate = useNavigate();


  const copyAnswer = () => {
  if (userTreino) {
    navigator.clipboard.writeText(userTreino)
      .then(() => {
        setSuccessMessageVisible(true);

        // Ocultar o mini card após 2 segundos (opcional)
        setTimeout(() => {
          setSuccessMessageVisible(false);
        }, 3000);
      })
      .catch(err => {
        console.error('Erro ao copiar para a área de transferência:', err);
      });
  }
};


  const Voltar = () => {
    navigate('/home');
  }


  useEffect(() => {
    retornaTreino()
      .then(async (dieta) => {
        if (dieta !== null) {
          await setUserTreino(dieta);          
        } else {         
          setUserTreino('');
        }
      })
      .catch((error) => {
        console.error('Erro ao obter os tickets:', error);
        setUserTreino('Erro ao obter os tickets');
      });
    
    })

  const handleDownloadPDF = () => {
    if (!userTreino) {
      return;
    }
    const pdf = new jsPDF();
    const margin = 10;
    const pageWidth = pdf.internal.pageSize.width - 8;
    const pageHeight = pdf.internal.pageSize.height - 1 * 8;
    const fontSize = 12;
   
    const splitText = pdf.splitTextToSize(`\nTreino Personalizado e Exclusivo \n\n${userTreino}`, pageWidth);

    let yPosition = margin;

    for (let i = 0; i < splitText.length; i++) {
      if (yPosition + 5 > pageHeight) {
        pdf.addPage();
        yPosition = margin;
      }

      pdf.setFontSize(fontSize);

      if( splitText[i].includes('Treino Personalizado e Exclusivo')){
        pdf.setFontSize(16)
      }else{
        pdf.setTextColor(0);
      }

      if (splitText[i].includes('Segunda-Feira') ||
          splitText[i].includes('Segunda') ||
          splitText[i].includes('Terça-Feira') ||
          splitText[i].includes('Terça') ||
          splitText[i].includes('Quarta-Feira') ||
          splitText[i].includes('Quarta') ||
          splitText[i].includes('Quinta-Feira') ||
          splitText[i].includes('Quinta')||
          splitText[i].includes('Sexta-Feira')||
          splitText[i].includes('Sexta')||
          splitText[i].includes('Sábado')|| splitText[i].includes('sábado')||
          splitText[i].includes('Domingo')|| splitText[i].includes('domingo')) {
        pdf.setTextColor(255, 0, 0); // Cor azul 
      } else {
        pdf.setTextColor(0); // Volta à cor padrão do texto (preto)
      }

      pdf.text(margin, yPosition, splitText[i]);

      yPosition += fontSize - 2;
    }


    const fileName = `MEU_TREINO.pdf`;
    pdf.save(fileName);
  };

  function shouldApplyBold(line) {
    const palavrasChave = ['Segunda' || 'Segunda-feira','Terça-feira' || 'Terça','Quarta-feira' || 'Quarta','Quinta-feira' || 'Quinta', 'Sexta-feira' || 'Sexta','Sábado' || 'Sabado',  "Domingo" || "Domingo", "", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado", "Domingo","Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira",];
    return palavrasChave.some(palavra => line.includes(palavra));
  }
  const dietaDividida = userTreino ? userTreino.split(/[\n]+/) : [];
  const cafeDaManhaIndex = dietaDividida.findIndex(line => 
    line.includes('Segunda-Feira') || line.includes('Segunda-feira') 
  );
  
  const lancheDaManhaIndex = dietaDividida.findIndex(line => 
    line.includes('Terça-Feira') || line.includes('Terça-feira') 
  );
  
  const almocoIndex = dietaDividida.findIndex(line => 
    line.includes('Quarta-Feira') || line.includes('Quarta-feira') 
  );
  
  const lancheDaTardeIndex = dietaDividida.findIndex(line => 
    line.includes('Quinta-Feira') || line.includes('Quinta-feira')  || line.includes('*Quinta*') 
  );
  
  const jantaIndex = dietaDividida.findIndex(line => 
    line.includes('Sexta-Feira') || line.includes('Sexta-feira') 
  );
  
  const treinoIndex = dietaDividida.findIndex(line => 
    line.includes('Sabado') || line.includes('Sábado') ||  line.includes('*Nota*') || line.includes('Observação') ||  line.includes('Nota') || line.includes('sábado') 
  );

  const treino2Index = dietaDividida.findIndex(line => 
    line.includes('Domingo') || line.includes('domingo')
  );

  const inicio = dietaDividida.slice(0, cafeDaManhaIndex)
  const cafeDaManha = dietaDividida.slice(cafeDaManhaIndex , lancheDaManhaIndex );
  const lancheDaManha = dietaDividida.slice(lancheDaManhaIndex , almocoIndex );
  const almoco = dietaDividida.slice(almocoIndex , lancheDaTardeIndex);
  const lancheDaTarde = dietaDividida.slice(lancheDaTardeIndex , jantaIndex);
  const janta = dietaDividida.slice(jantaIndex,  treinoIndex )
  const treino = dietaDividida.slice(treinoIndex ? treinoIndex : null, treino2Index);
  const treino2 = dietaDividida.slice(treino2Index ? treino2Index : null);

  return (
    
    <div className='flex flex-column p-4 md:w-5/6 mx-auto'>
        <h1 className='font-black m-5 p-2 text-center text-red-600 border-b '>TREINO</h1>
    

{userTreino && (
  <div>
    <div className='flex items-center content-center justify-evenly '>
    <button className="my-2 bg-gray-800 text-white px-5 py-3 md:py-2 mx-1 rounded-2xl hover:bg-[#ccc]" onClick={handleDownloadPDF} >
        Baixar PDF
      </button>
      
         <button className=" hover:bg-[#ccc] text-white bg-gray-800 px-5 py-3 my-4 mx-1 rounded-2xl" onClick={copyAnswer}>
           Copiar DIETA
         </button>
    </div>
    <div>
      {successMessageVisible && (
         <div className="chat-error text-center justify-center bg-gray-800 text-white py-3 px-1 mx-auto w-[90%]">
           Dieta copiada com sucesso! Agora só colar!
         </div>
       )}   
    </div>
    </div>
    )}


      {userTreino && (<div>
       {inicio.map((line) => line).length !== 0 && <div className='bg-green-500 font-bold text-xl text-center pb-2 mt-5 shadow-xl rounded-xl'>
          {inicio.map((line, index) => (
            <p className='p-1 m-0' key={index} style={{ color: 'white' }}>{line}</p>
          ))}
        </div>
      }
        <div className='bg-red-500 mt-5 py-2 shadow-xl rounded-xl'>
          {cafeDaManha.map((line, index) => (
            <p className='p-2 m-0' key={index} style={{ color: 'white' }}>
               {shouldApplyBold(line) ? (
        <strong className='my-3'>{line}</strong>
      ) : (
        line
      )}
            </p>
          ))}
        </div>
        {lancheDaManhaIndex >2 && ( <div>
        <div className='bg-red-600 mt-5 py-2 shadow-xl rounded-xl' >
          {lancheDaManha.map((line, index) => (
            <p className=' p-2 m-0' key={index} style={{ color: 'white' }}>
               {shouldApplyBold(line) ? (
        <strong className='my-3'>{line}</strong>
      ) : (
        line
      )}
            </p>
          ))}
        </div>
        <div className='bg-red-700 mt-5 py-2 shadow-xl rounded-xl' >
          {almoco.map((line, index) => (
            <p className='p-2 m-0' key={index} style={{ color: 'white' }}>
             {shouldApplyBold(line) ? (
        <strong className='my-3'>{line}</strong>
      ) : (
        line
      )}
            </p>
          ))}
        </div>
        <div className='bg-red-800 mt-5 py-2 shadow-xl rounded-xl'>
          {lancheDaTarde.map((line, index) => (
            <p className='p-2 m-0 ' key={index} style={{ color: 'white' }}>
               {shouldApplyBold(line) ? (
        <strong className='my-3'>{line}</strong>
      ) : (
        line
      )}
            </p>
          ))}
        </div>
        <div className='bg-red-900 mt-5 py-2 shadow-xl rounded-xl'>
          {janta.map((line, index) => (
            <p className='p-2 m-0' key={index} style={{ color: 'white' }}>
               {shouldApplyBold(line) ? (
        <strong className='my-3'>{line}</strong>
      ) : (
        line
      )}
            </p>
          ))}
        </div>
        <div className='bg-red-800 mt-5 py-2 shadow-xl rounded-xl'>
  {treino && (treino.map((line, index) => (
    <p className='p-2 m-0' key={index} style={{ color: 'white' }}>
      {shouldApplyBold(line) ? (
        <strong className='my-3'>{line}</strong>
      ) : (
        line
      )}
    </p>
  )))}
</div>

<div className='bg-red-900 mt-5 py-2 shadow-xl rounded-xl'>
  {treino2 && (treino2.map((line, index) => (
    <p className='p-2 m-0' key={index} style={{ color: 'white' }}>
      {shouldApplyBold(line) ? (
        <strong className='my-3'>{line}</strong>
      ) : (
        line
      )}
    </p>
  )))}
</div>
</div>)}
      </div>)}
      
    
    
      <button className="mt-4 bg-gray-600 hover:bg-[#ccc] text-white mx-auto px-5 py-3 rounded-2xl " onClick={Voltar}>
        Página Inicial
      </button>
      
      
         
  
     </div>
  );
}
