import { createContext, useContext, useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import { auth } from '../firebase';
import { get, getDatabase, ref, set, update } from 'firebase/database';
import { useLocation, useNavigate } from 'react-router-dom';


const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  const createUser = async (email, password) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const uid = userCredential.user.uid;
    // Crie um nó no Realtime Database para armazenar informações do usuário
    const db = getDatabase();
    const userRef = ref(db, "users/" + uid);
    set(userRef, {
      email: email,
      Tickets: 0,
      Treino: "",
      lastLogin: new Date().toISOString()
    });
    setUser(userCredential.user);
  };


  const signIn = async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const uid = userCredential.user.uid;
    // Crie um nó no Realtime Database para armazenar informações do usuário
    const db = getDatabase();
    const userRef = ref(db, "users/" + uid);
    update(userRef, {
      email: email,
      lastLogin: new Date().toISOString()
    });
    setUser(userCredential.user);
  };

  const retornaTicket = async () => {
    try {
      const current = auth.currentUser;
      if (current != null) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          const userTickets = userData.Tickets;
          return userTickets; // Retorna o valor dos tickets
        } else {
          return null; // Retorna null se o usuário não existir
        }
      } else {
        return null; // Retorna null se não houver usuário autenticado
      }
    } catch (error) {
      console.error("Erro ao recuperar tickets:", error);
      return null; // Retorna null em caso de erro
    }
  };

  const adicionarTicket = async () => {

    try {
      const current = auth.currentUser;
      if (current) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {

          const Tickets = snapshot.val().Tickets
          // eslint-disable-next-line
          if (Tickets == 0) {
            const setTicket = "TreinoNormal"
            update(userRef, {
              email: current.email,
              Tickets: setTicket,
              lastLogin: new Date().toISOString()
            })
            navigate(location.pathname);
          } else {
          }
        }
      }
    } catch (error) {
      console.error("Erro ao recuperar tickets:", error);
    }
  };

  const adicionarTreino = async (treinoSalvo) => {
    try {
      const current = auth.currentUser;
      if (current) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {


          // eslint-disable-next-line

          const setTreino = treinoSalvo
          update(userRef, {
            email: current.email,
            Treino: setTreino,
            lastLogin: new Date().toISOString()
          })

        }
      }
    } catch (error) {
      console.error("Erro dieta", error);
    }
  };

  const retornaTreino = async () => {
    try {
      const current = auth.currentUser;
      if (current != null) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          const userTreino = userData.Treino;
          return userTreino; // Retorna o valor dos tickets
        } else {
          return null; // Retorna null se o usuário não existir
        }
      } else {
        return null; // Retorna null se não houver usuário autenticado
      }
    } catch (error) {
      console.error("Erro ao recuperar Treino:", error);
      return null; // Retorna null em caso de erro
    }
  };

  const retirarTicket = async () => {

    try {
      const current = auth.currentUser;
      if (current) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {

          const Tickets = snapshot.val().Tickets
          // eslint-disable-next-line
          if (Tickets) {
            const setTicket = 0
            update(userRef, {
              email: current.email,
              Tickets: setTicket,
              lastLogin: new Date().toISOString()
            })
            navigate(location.pathname);
          } else {
          }
        }
      }
    } catch (error) {
      console.error("Erro ao recuperar tickets:", error);
    }
  };


  const logout = () => {
    return signOut(auth)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser !== null && window.location.pathname === "/") {
        navigate('/home');
      }

    });
    return () => {
      unsubscribe();
    };
  }, [navigate]);

  return (
    <UserContext.Provider value={{ createUser, user, adicionarTreino, retornaTreino, logout, signIn, retornaTicket, adicionarTicket, retirarTicket }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
