import React, { useRef, useState } from 'react';
import './Emagresce.css';
import PlanoIndividual from "./../img/PlanoIndividual.png"
import Dieta3Meses from "./../img/Dieta3Meses.png"
 import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function Dieta() {
  const pixCodeRef = useRef(null);
  const [isCopied, setIsCopied] = useState(false);
  const { adicionarTicket } = UserAuth();
  const navigate = useNavigate();

  const Voltar = () => {
    navigate('/home')
  }

  const copyPixCode = () => {
    pixCodeRef.current.select();
    document.execCommand('copy');
    setIsCopied(true);
    adicionarTicket()
    // adicionarTicket("Dieta3Meses")
    // Reset isCopied after 3 seconds
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  const defaultPixCode = "00020126580014BR.GOV.BCB.PIX013620cd069d-9912-4b21-bb4b-bb0439835ed3520400005303986540529.995802BR592552.027.375 ALTAIR JUNIOR 6009SAO PAULO61080540900062240520qLCeNNvnbp3PcyAvndoq6304546E"
  return (
    <div className="emagresce-container">
      <img src={PlanoIndividual} alt="Imagem de Emagrecimento" className="emagrecimento-image" />
      <div className="payment-card w-80 md:w-1/2">
        <h2 className="payment-card-title">Detalhes do Pagamento</h2>
        <img
          src={Dieta3Meses}
          alt="emagrecer"
          className="payment-image mb-5 rounded-2xl md:w-3/5 md:mx-auto "
        />
        <div className="payment-card-content">
          <label htmlFor="pix-code">Código PIX Copia e Cola:</label>
          <input
            type="text"
            id="pix-code"
            className="pix-input"
            ref={pixCodeRef}
            defaultValue={defaultPixCode}
          />
          <button className="copy-button" onClick={copyPixCode}>
            Copiar Código PIX
          </button>
          {isCopied && (
            <div className="success-message">Código PIX copiado com sucesso!</div>
          )}
        </div>
      </div>
      <button className="mt-5 text-white px-10 py-3 rounded-lg bg-red-500" onClick={Voltar}>
        Voltar
      </button>
    </div>
  );
}
