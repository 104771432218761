import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import HamburgerMenu from './HamburgerMenu';
import ChatContainer from './ChatContainer';
import Modal from './Modal';
import banner from '../img/NewIMG.png';
import banner2 from "../img/e8db1aa2-02bb-405a-94e1-a5dc362041ce.png"
import 'bootstrap/dist/css/bootstrap.css';

function Home() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const [answer, setAnswer] = useState('');
  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 768px)').matches);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const openBuyModal = () => {
    setIsBuyModalOpen(true);
  };

  const closeBuyModal = () => {
    setIsBuyModalOpen(false);
  };

  const sendRequestToGPT = async (data) => {
    try {
      const params = {
        model: "gpt-4-1106-preview",
        max_tokens: 2000,
        messages: [
          {
            role: 'system',
            content: 'Mantenha um tom profissional de personal e seja curto na resposta,somente o treino'
          },
          {
            role: 'user',
            content: data
          }
        ],
      };

      const result = await axios.post("https://api.openai.com/v1/chat/completions", params, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_CHATGPT_KEY}`,
          'Content-Type': 'application/json'
        }
      });

      const answerFromAPI = result.data.choices[0].message.content;
      setAnswer(answerFromAPI);

    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="App pb-5">
      <header className="App-header">
        <HamburgerMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />
        <h1> PERSONAL INTELIGENTE</h1>
      </header>
      <main className="App-main">
        <div className="hero mb-5">
          {isMobile ?
            (<img src={banner} alt="banner" onClick={openBuyModal} className="w-auto object-cover" />)
            :
            (<img src={banner2} alt="banner2" onClick={openBuyModal} className="w-auto" />)}
        </div>
        <ChatContainer sendRequestToGPT={sendRequestToGPT} answer={answer} />
      </main>
      <Modal isOpen={isBuyModalOpen} onClose={closeBuyModal} />
    </div>
  );
}

export default Home;
