import React from "react"
export default function Termos() {
    return (
        <div>
            <header>
                <h1>Termos e Condições de Uso</h1>
            </header>
            <main>
                <section>
                    <h2>Natureza da Plataforma</h2>
                    <p>
                        A Personal Inteligente utiliza algoritmos avançados de processamento de dados para analisar as informações
                        fornecidas por você, como preferências de exercicios, metas de saúde e histórico
                        médico, a fim de oferecer sugestões personalizadas e adequadas ao seu perfil. É importante ressaltar que
                        as orientações fornecidas pela plataforma são de natureza geral e informativa, não substituindo a
                        importância do acompanhamento individualizado por um profissional da área qualificado.

                        Ao utilizar a Personal Inteligente, você terá acesso a recomendações que podem auxiliar na promoção de um
                        treinamento balanceado e saudável, de acordo com as informações que você forneceu. No entanto, é crucial
                        compreender que a plataforma não tem a capacidade de considerar nuances específicas de cada caso, como
                        condições médicas pré-existentes, interações medicamentosas ou necessidades dietéticas especiais.

                        Sempre recomendamos que você compartilhe as orientações da Personal Inteligente com um personal ou
                        professor antes de implementar quaisquer mudanças significativas em seus exercicios. Profissionais de Educação fisica podem
                        oferecer avaliações detalhadas e adaptadas ao seu contexto individual, considerando fatores que não
                        podem ser adequadamente abordados apenas por meio de algoritmos.

                        Lembramos ainda que as informações fornecidas à Personal Inteligente devem ser precisas e atualizadas para
                        garantir que as recomendações sejam as mais adequadas possíveis. A plataforma é uma ferramenta de
                        auxílio e conscientização, mas a responsabilidade final por suas escolhas alimentares e de saúde
                        permanece com você e seu profissional de saúde de confiança. </p>
                </section>
                <section>
                    <h2>Orientação Profissional</h2>
                    <p>
                        Sempre recomendamos que você consulte um personal ou outro profissional de Educação Fisica antes de iniciar
                        qualquer dieta ou plano alimentar. A Personal Inteligente não é capaz de levar em consideração todas as
                        nuances individuais de saúde condições médicas preexistentes ou outros fatores que um profissional de Educação Fisica 
                        qualificado pode avaliar. Sua saúde é uma prioridade, e somente um profissional devidamente licenciado e com formação 
                        adequada pode fornecer orientações personalizadas e embasadas em conhecimento científico, levando em consideração seu 
                        histórico de saúde, exames médicos e outros aspectos relevantes.

                        É importante entender que a Personal Inteligente é uma ferramenta que opera com base em algoritmos e
                        informações fornecidas por você, mas não pode substituir o discernimento clínico e a expertise de um
                        profissional de saúde humano. A interação direta com um profissional permite uma análise completa e
                        abrangente, considerando todos os aspectos da sua saúde e bem-estar.

                        Lembre-se de que cada pessoa é única, e as orientações de exercicios devem ser adaptadas a cada caso
                        individual. Um profissional de saúde qualificado pode avaliar seu perfil de saúde de maneira holística,
                        levando em conta fatores como histórico médico, medicamentos em uso, exames laboratoriais e outras
                        informações relevantes. Portanto, ao utilizar a Personal Inteligente, utilize as informações fornecidas
                        como um complemento à orientação de um profissional de saúde, e não como um substituto completo.

                        Nunca hesite em buscar a opinião de um especialista antes de tomar decisões importantes relacionadas à
                        sua saúde, exercícios e nutrição. Isso garantirá que você esteja tomando as medidas mais adequadas e seguras para
                        atingir suas metas de saúde de maneira sustentável e informada. </p>
                </section>
                <section>
                    <h2>Limitações da Inteligência Artificial</h2>
                    <p>
                        Entenda que a Personal Inteligente opera com base em algoritmos e dados disponíveis até o momento de sua
                        última atualização. Embora envidemos esforços contínuos para fornecer orientações precisas e relevantes,
                        a plataforma pode conter erros ou não estar atualizada com as informações médicas mais recentes. A
                        ciência do treinamento e da saúde está em constante evolução, e novas descobertas podem influenciar as
                        abordagens recomendadas.

                        Portanto, é essencial que você utilize o bom senso ao interpretar as recomendações geradas pela
                        plataforma. Considere-as como um ponto de partida para sua jornada de cuidados com a saúde, mas esteja
                        ciente de que a consulta regular a fontes confiáveis, como profissionais de saúde atualizados e
                        pesquisas científicas recentes, é fundamental para tomar decisões informadas.

                        Lembre-se também de que sua saúde é única e pode ser afetada por uma série de fatores individuais. A
                        Personal Inteligente não pode substituir a avaliação minuciosa de um profissional de saúde que pode levar
                        em conta sua situação específica, histórico médico, exames de laboratório e outros elementos relevantes.

                        Ao utilizar a Personal Inteligente, equilibre suas decisões com informações obtidas de fontes confiáveis e
                        atualizadas, e não hesite em buscar o aconselhamento de um profissional de Educação Física sempre que necessário.
                        A colaboração entre a tecnologia e a expertise humana é fundamental para garantir que você esteja
                        fazendo escolhas saudáveis e bem fundamentadas para o seu bem-estar. </p>
                </section>

                <section>
                    <h2>Responsabilidade e Riscos</h2>
                    <p>
                        Ao usar a Personal Inteligente, você concorda em assumir total responsabilidade por suas decisões em
                        relação à sua saúde e Treinos. A utilização da plataforma é por sua conta e risco, e não nos
                        responsabilizamos por qualquer consequência adversa resultante do uso ou má interpretação das
                        recomendações da Personal Inteligente. É fundamental compreender que as recomendações geradas pela
                        plataforma são baseadas em informações fornecidas por você e processadas por algoritmos, mas não
                        substituem a orientação de um profissional de saúde qualificado.

                        Ao optar por utilizar a Personal Inteligente, você deve estar ciente dos limites inerentes à tecnologia e
                        do fato de que decisões de saúde devem ser tomadas com base em uma variedade de fatores, incluindo
                        informações médicas atualizadas e aconselhamento especializado. Recomendamos fortemente que você busque
                        a orientação de um personal, médico ou outro profissional de Educação Fisica antes de implementar qualquer
                        mudança significativa em seu estilo de vida, a fim de garantir que suas escolhas sejam
                        compatíveis com suas necessidades individuais.

                        Lembre-se de que sua saúde é uma prioridade, e tomar decisões informadas é essencial para promover seu
                        bem-estar a longo prazo. A Personal Inteligente pode ser uma ferramenta útil, mas deve ser usada com
                        discernimento e em conjunto com a orientação de profissionais de saúde competentes. </p>
                </section>
                <section>
                    <h2>Modificações nos Termos</h2>
                    <p>
                        Reservamo-nos o direito de atualizar ou modificar estes termos a qualquer momento. Recomendamos que você
                        revise periodicamente esta página para se manter informado sobre quaisquer alterações. O uso contínuo da
                        Personal Inteligente após quaisquer modificações nos termos indica sua aceitação dessas mudanças. É
                        importante estar ciente dos termos mais recentes ao utilizar a plataforma, a fim de garantir que você
                        esteja ciente de quaisquer atualizações que possam afetar sua interação com a Personal Inteligente.

                        Entendemos a importância de manter nossos usuários informados e asseguramos que qualquer atualização nos
                        termos seja feita com transparência e considerando o melhor interesse dos usuários. Agradecemos por
                        escolher a Personal Inteligente como parte de sua jornada de saúde e bem-estar, e estamos comprometidos em
                        fornecer uma plataforma confiável e eficaz para auxiliá-lo em suas metas.


                    </p>
                </section>
                <section>
                    <h2>Concordância com os Termos.</h2>
                    <p>
                        Ao continuar a usar a Personal Inteligente, você indica que leu, compreendeu e concordou com estes termos e
                        condições de uso. Se você não concordar com esses termos, solicitamos que não utilize a plataforma. A
                        aceitação destes termos é um requisito fundamental para a utilização da Personal Inteligente.

                        Caso você tenha alguma dúvida ou preocupação sobre os termos, funcionamento da plataforma ou qualquer
                        outro assunto relacionado, encorajamos você a entrar em contato conosco através dos canais de suporte
                        indicados em nosso site. Estamos aqui para fornecer esclarecimentos e assistência, garantindo que você
                        tenha a melhor experiência possível ao utilizar a Personal Inteligente como parte de sua jornada de saúde.

                        Agradecemos sua compreensão e sua escolha em fazer uso da nossa plataforma para ajudá-lo a tomar
                        decisões informadas em relação à sua dieta e bem-estar. </p>
                </section>
                <section>
                    <h2>Exclusão de Garantias</h2>
                    <p>
                        Você compreende e concorda que a plataforma é fornecida "no estado em que se encontra" e não oferecemos
                        garantias expressas ou implícitas sobre a precisão, confiabilidade ou adequação das informações
                        fornecidas.
                    </p>
                </section>
                <section>
                    <h2>Modificações nos Termos</h2>
                    <p>
                        Reservamo-nos o direito de atualizar ou modificar estes termos a qualquer momento. Recomendamos que você
                        revise periodicamente esta página para se manter informado sobre quaisquer alterações.
                    </p>
                </section>
                <section>
                    <h2>Resolução de Conflitos</h2>
                    <p>
                        Qualquer disputa ou conflito relacionado ao uso da plataforma será regido pelas leis do BRASIL, e ambas
                        as partes concordam em submeter-se à jurisdição exclusiva dos tribunais competentes da jurisdição da
                        comarca de Volta Redonda estado do Rio de janeiro.
                    </p>
                </section>
                <section>
                    <h2>Política de Reembolso</h2>
                    <p>

                        Política de Reembolso de 7 Dias - CDC (Código de Defesa do Consumidor)

                        Nosso compromisso é oferecer a melhor experiência possível aos nossos usuários. Em consonância com o
                        Código de Defesa do Consumidor (Lei nº 8.078/1990), estabelecemos uma Política de Reembolso de 7 Dias,
                        que permite aos nossos clientes exercerem o direito de arrependimento em suas compras online.

                        O consumidor tem o direito de solicitar o reembolso de um produto ou serviço adquirido em nosso site no
                        prazo de 7 (sete) dias corridos a partir da data de recebimento do produto ou da contratação do serviço,
                        sem a necessidade de apresentar qualquer justificativa.

                        O reembolso será processado de acordo com a forma de pagamento originalmente utilizada pelo consumidor,
                        dentro de um prazo máximo de 7 (sete) dias úteis a partir da data de recebimento da solicitação de
                        reembolso.

                        O produto ou serviço a ser reembolsado deve ser devolvido em sua embalagem original, sem indícios de uso
                        ou danos, acompanhado de todos os acessórios e manuais. Caso o produto não esteja em conformidade com
                        essas condições, reservamo-nos o direito de rejeitar o reembolso ou aplicar uma taxa de depreciação
                        proporcional.

                        A política de reembolso de 7 dias não se aplica a produtos ou serviços que, por sua natureza, não possam
                        ser devolvidos ou reembolsados, conforme previsto no Código de Defesa do Consumidor.

                        Entendemos a importância de fornecer informações claras e detalhadas sobre nossa política de reembolso.
                        Portanto, recomendamos que avalie cuidadosamente a decisão de adquirir acesso à Personal Inteligente antes
                        de prosseguir com a compra. Em caso de dúvidas ou necessidade de esclarecimentos adicionais, sinta-se à
                        vontade para entrar em contato conosco por meio dos canais de suporte fornecidos em nosso site.

                        Agradecemos sua compreensão e sua escolha de utilizar a Personal Inteligente como parte de sua jornada de
                        saúde. Estamos aqui para ajudá-lo a tomar decisões informadas e promover seu bem-estar. </p>
                </section>
            </main>
        </div>
    )
}