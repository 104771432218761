import React from "react";
import Signin from "./components/Signin";
import Signup from "./components/Signup";
import { Route, Routes } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import Emagresce from "./components/Emagresce";
import MassaMuscular from "./components/MassaMuscular";
import DietaTreino from "./components/DietaTreino";
import Home from "./components/Home";
import Termos from "./components/Termos";
import Suporte from "./components/ComprarPage";
import Definicao from "./components/Definicao";
import DefinicaoMassa from "./components/DefinicaoMassa";
import Dieta from "./components/Dieta";
import LocalDieta from './components/LocalDieta'

function App() {
  return (
    <div>
      <AuthContextProvider>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/termos" element={<Termos />} />
          <Route path="/suporte" element={<Suporte />} />
          <Route path="/meutreino" element={<LocalDieta />} />
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/emagresce"
            element={
              <ProtectedRoute>
                <Emagresce />
              </ProtectedRoute>
            }
          />
          <Route
            path="/massamuscular"
            element={
              <ProtectedRoute>
                <MassaMuscular />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dietatreino"
            element={
              <ProtectedRoute>
                <DietaTreino />
              </ProtectedRoute>
            }
          />
          <Route
            path="/definicao"
            element={
              <ProtectedRoute>
                <Definicao />
              </ProtectedRoute>
            }
          />
          <Route
            path="/definicaomassa"
            element={
              <ProtectedRoute>
                <DefinicaoMassa />
              </ProtectedRoute>
            }
          />

          <Route
            path="/dieta3meses"
            element={
              <ProtectedRoute>
                <Dieta />
              </ProtectedRoute>
            }
          />

        </Routes>
      </AuthContextProvider>
    </div>
  );
}

export default App;
